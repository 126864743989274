import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet';
import Select from 'react-select';
import Header from '../../common/header/Header';
import Button from '../../common/button/Button';
import Confetti from '../../assets/confetti.png';
import VoteLady from '../../assets/voteLady.png';
import BackButton from '../../common/button/BackButton';
import SkeletonLoader from '../../common/skeletonLoader/SkeletonLoader';
import moment from 'moment';
import axios from 'axios';

const EmployerDetails = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const selectedCategory = location.state.selectedCategory;
  const voterId = location.state.voterId;

  const [fullNameInputValue, setFullNameInputValue] = useState('');
  const [costCenterInputValue, setCostCenterInputValue] = useState('');
  const [regions, setRegions] = useState([]);
  const [costCenters, setCostCenters] = useState([]);
  const [fullNames, setFullNames] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(false);
  const [selectedFullName, setSelectedFullName] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedCostCenter, setSelectedCostCenter] = useState(null);
  const [comment, setComment] = useState('');
  const [nomineeAka, setNomineeAka] = useState('');
  const [error, setError] = useState(null);

  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);

  const [fieldsIsEmpty, setFieldsIsEmpty] = useState(false);

  const [firstImageLoaded, setFirstImageLoaded] = useState(false);
  const [secondImageLoaded, setSecondImageLoaded] = useState(false);

  const [InUnit, setInUnit] = useState(null);
  const [userId, setUserId] = useState(null);
  const [storedUserId, setStoredUserId] = useState('');

  useEffect(() => {
    fetchFilteredData();
  }, [selectedFullName, selectedRegion, selectedCostCenter, InUnit]);

  useEffect(() => {
    if (bottomSheetOpen) {
      const firstImg = new Image();
      firstImg.src = Confetti;
      firstImg.onload = () => setFirstImageLoaded(true);

      const secondImg = new Image();
      secondImg.src = VoteLady;
      secondImg.onload = () => setSecondImageLoaded(true);
    }
  }, [bottomSheetOpen]);

  useEffect((storedUserId) => {
    storedUserId = localStorage.getItem('userId');

    if (storedUserId) {
      setUserId(storedUserId);
    }

  }, [storedUserId]);

  const fetchFilteredData = useCallback(() => {
    try {
      const queryParams = new URLSearchParams({
        region: selectedRegion?.value || '',
        costCenter: selectedCostCenter?.value || '',
        fullName: selectedFullName?.label || '',
        inUnit: InUnit || '',
      }).toString();

      axios.get(`${process.env.REACT_APP_URL}/final?${queryParams}`)
      .then(response => {
        if (response.data.status === 'Success') {
          const data = response.data.data;

          setRegions(data.Region.map(region => ({
            value: region.value,
            label: region.label
          })));

          setCostCenters(data.CostCenter.map(costCenter => ({
            value: costCenter.value,
            label: costCenter.label
          })));
  
          setFullNames(data.FullName.map(fullName => ({
            value: fullName.value,
            label: fullName.label
          })));
        } else {
          throw new Error('Failed to fetch filtered data');
        }
      })
      .catch(error => console.log(error))
    } catch (error) {
      console.error('Error fetching filtered data:', error);
      setError('Error fetching filtered data');
    }
  }, [selectedFullName, selectedRegion, selectedCostCenter, InUnit]);

  useEffect(() => {
    if (fullNames.length === 1) {
      if (!(selectedFullName?.label == fullNames[0].label)) {
        setFullNames(fullNames);
      };
    };

    if (costCenters.length === 1) {
      if (!(selectedCostCenter?.value == costCenters[0].value)) {
        setSelectedCostCenter(costCenters[0]);
      };
    };

    if (regions.length === 1) {
      if (!(selectedRegion?.value == regions[0].value)) {
        setSelectedRegion(regions[0]);
      };
    };

  }, [fullNames, costCenters, regions]);


  const fullNamesFilter = async (query) => {
    try {
      if (query) {
        axios.get(`${process.env.REACT_APP_URL}/fullNames?search=${query}`)
        .then(response => {
          if (response.data.status === 'Success') {
            const data = response.data.data;
  
            setFullNames(data.map(fullName => ({
              value: fullName.EE_Number,
              label: fullName.FullName,
              InUnit: fullName.InUnit
            })));
  
            const selectedInUnit = data.find(name => name.FullName === selectedFullName?.label);
  
            const location = selectedInUnit ? (selectedInUnit.InUnit ? "On-Site" : "Head Office") : 'Head Office';
  
            setInUnit(location);
          } else {
            throw new Error('Failed to fetch full names');
          }
        }); 
      }
    } catch (error) {
      console.error('Error fetching full names:', error);
      setError('Error fetching full names');
    };
  };

  const costCentersFilter = async (query) => {
    try {
      if (query) {
        axios.get(`${process.env.REACT_APP_URL}/costCenters?search=${query}&inUnit=${InUnit}`)
        .then(response => {
          if (response.data.status === 'Success') {
            const data = response.data.data;
  
            setCostCenters(data.map(costCenter => ({
              value: costCenter.Cost_Center,
              label: costCenter.Cost_Center
            })));
          } else {
            throw new Error('Failed to fetch cost centers');
          }
        });
      }
    } catch (error) {
      console.error('Error fetching cost centers:', error);
      setError('Error fetching cost centers');
    };
  };

  const handleFullNameSelect = (selectedOption) => {
    setSelectedFullName(selectedOption);
    setSelectedRegion(null);
    setSelectedCostCenter(null);
  };

  const handleRegionSelect = (selectedOption) => {
    setSelectedRegion(selectedOption);
    setSelectedCostCenter(null);
  };

  const handleCostCenterSelect = (selectedOption) => {
    setSelectedCostCenter(selectedOption);
  };

  const handleFullNameInputChange = (inputValue) => {
    setFullNameInputValue(inputValue);
    fullNamesFilter(inputValue);
  };

  const handleCostCenterInputChange = (inputValue) => {
    setCostCenterInputValue(inputValue);
    costCentersFilter(inputValue)
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleNomineeAkaChange = (e) => {
    setNomineeAka(e.target.value);
  };

  const handleInUnitSelect = (selectedOption) => {
    setInUnit(selectedOption.value);
  };

  const clearSelections = (event) => {
    event.preventDefault();
    setSelectedFullName(null);
    setSelectedRegion(null);
    setSelectedLocation(null);
    setSelectedCostCenter(null);
    setFullNameInputValue('');
    setCostCenterInputValue('');
    setFullNames([]);
    setCostCenters([]);
    setRegions([]);
    setComment('');
    setNomineeAka('');
    setInUnit('');
  };

  const customStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', border: 'none', borderBottom: '2px solid #CFCFCF', fontFamily: 'Light', boxShadow: 'none' }),
    indicatorSeparator: (styles) => ({ display: 'none' }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontFamily: 'Light',
        color: '#CFCFCF'
      };
    }
  };

  const inUnitOptions = [
    { value: 'On-Site', label: 'On-Site' },
    { value: 'Head Office', label: 'Head Office' }
  ];

  useEffect(() => {
    if (selectedFullName) {
      fullNamesFilter(fullNameInputValue);
    }
  }, [selectedFullName, fullNameInputValue]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isFormValid = formRef.current.checkValidity();

    if (isFormValid && selectedFullName && selectedRegion && selectedCostCenter && InUnit.length && comment.length) {
      console.log('Form is valid!');
      formRef.current.reset();

      // Open the bottom sheet if validation passes
      setBottomSheetOpen(true);

      setFieldsIsEmpty(false);

      const formData = {
        employeeName: selectedFullName?.label,
        employeeNumber: selectedFullName?.value,
        category: selectedCategory,
        comment: comment,
        userId: userId,
        IdNumber: voterId,
        voteDate: moment(new Date()).format('YYYY-MM-DD'),
      };

      try {
        axios.post(`${process.env.REACT_APP_URL}/votingData`, formData)
        .then(response => {
          if (response.data.status === 'Success') {
            // console.log('Success:', response.data.message);
            setBottomSheetOpen(true);
          } else {
            throw new Error('Network response was not ok');
          }
        })
        .catch(error => console.log(error));
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      setFieldsIsEmpty(true);
    }
  };

  return (
    <div className='flex min-h-full flex-1 flex-col justify-center px-6 py-5 lg:px-8'>
      <BackButton />
      <div className='sm:mx-auto sm:w-full sm:max-w-sm md:max-w-2xl'>
        <Header width='size-32' padding='pb-8' />
        <div>
          <p className='fontLight text-[#666666] pb-6 leading-snug md:text-xl'>Please provide the details of the employee you are voting for</p>
        </div>
        <div className='sm:mx-auto sm:w-full sm:max-w-sm md:max-w-2xl'>
          <form action='#' method='POST' className='space-y-6' onSubmit={handleSubmit} ref={formRef}>
            <Button
              onClick={clearSelections}
              color='text-red-600'
              width='w-32'
              justifySelf='justify-self-end'
              fontSize='text-sm'
            >
              Clear Selections
            </Button>

            {fieldsIsEmpty && <p className='text-base text-red-600 uppercase bg-red-50 p-2 animate-pulse text-center'>* Fill in Required Fields</p>}

            {/* Nominee Full Name */}
            <div className='pb-1'>
              <label className='flex text-xl gap-1'>
                Nominee Full Name <p className={!selectedFullName && 'text-red-600 animate-pulse'}>*</p>
              </label>
              <div>
                <Select
                  options={fullNames}
                  value={selectedFullName}
                  onChange={handleFullNameSelect}
                  onInputChange={handleFullNameInputChange}
                  inputValue={fullNameInputValue}
                  isSearchable={true}
                  placeholder='Nominee Full Name'
                  styles={customStyles}
                />
              </div>
            </div>

            {/* Nominee Known As */}
            <div className='pb-1'>
              <label htmlFor='nomineeAka' className='flex gap-1 text-xl'>
                Nominee Known As <p className={!nomineeAka && 'text-red-600 animate-pulse'}></p>
              </label>
              <div className='mt-2'>
                <input
                  id='nomineeAka'
                  name='nomineeAka'
                  type='text'
                  onChange={handleNomineeAkaChange}
                  value={nomineeAka}
                  // required
                  placeholder='Nominee Known As'
                  className='fontLight block w-full px-2 pb-2 text-base text-black border-b-2 border-[#CFCFCF] placeholder:text-[#CFCFCF] placeholder:text-base focus:outline-none'
                />
              </div>
            </div>

            {/* Province */}
            <div className='pb-1'>
              <label className='flex gap-1 text-xl'>
                Province <p className={!selectedRegion && 'text-red-600 animate-pulse'}>*</p>
              </label>
              <div>
                <Select
                  options={regions}
                  value={selectedRegion}
                  onChange={handleRegionSelect}
                  isSearchable={false}
                  placeholder='Province'
                  styles={customStyles}
                />

              </div>
            </div>

            {/* Unit Name */}
            <div className='pb-1'>
              <label className='flex gap-1 text-xl'>
                Unit Name <p className={!selectedCostCenter && 'text-red-600 animate-pulse'}>*</p>
              </label>
              <div>
                <Select
                  options={costCenters}
                  value={selectedCostCenter}
                  onChange={handleCostCenterSelect}
                  onInputChange={handleCostCenterInputChange}
                  inputValue={costCenterInputValue}
                  isSearchable={true}
                  placeholder='Unit Name'
                  styles={customStyles}
                />
              </div>
            </div>

            {/* Above/In-Unit */}
            <div className='pb-1'>
              <label className='flex gap-1 text-xl'>
                Head Office / On-site <p className={!selectedLocation && 'text-red-600 animate-pulse'}>*</p>
              </label>
              <div>
                <Select
                  options={inUnitOptions}
                  value={inUnitOptions.find(option => option.value === InUnit)}
                  onChange={handleInUnitSelect}
                  isSearchable={false}
                  placeholder='Head Office / On-site'
                  styles={customStyles}
                />
              </div>
            </div>


            {/* Reason */}
            <div className='pb-1'>
              <label htmlFor='reason' className='flex gap-1 text-xl'>
                Reason <p className={!comment && 'text-red-600 animate-pulse'}>*</p>
              </label>
              <div className='mt-2'>
                <textarea
                  id='reason'
                  name='reason'
                  required
                  value={comment}
                  onChange={handleCommentChange}
                  placeholder='Share what makes them stand out and why they deserve recognition in this category'
                  minLength={50}
                  maxLength={150}
                  rows={3}
                  className='fontLight block w-full px-2 pb-2 text-base text-black border-2 border-[#CFCFCF] placeholder:text-[#CFCFCF] placeholder:text-base focus:outline-none'
                />
              </div>
            </div>

            <Button
              type='submit'
              bgColor='bg-gradient-to-r from-[#00BBD3] from-44% to-[#53BC7F] to-100%'
              shadow='shadow-[1px_5px_13px_3px_rgba(11,133,149,0.46)]'
              padding='px-3 py-3'
              color='text-white'
              onClick={handleSubmit}
              width='w-full'
              fontSize='text-xl'
            >
              Submit
            </Button>
          </form>

          {/* Bottom Sheet */}
          <BottomSheet
            open={bottomSheetOpen}
            snapPoints={({ minHeight }) => minHeight}
          >
            <div className='flex min-h-full flex-1 flex-col marker:*:justify-center px-6 py-5 lg:px-8'>
              <div className='sm:mx-auto sm:w-full sm:max-w-sm md:max-w-sm'>
                <div className='pb-5 -mt-7 flex flex-col'>
                  {!firstImageLoaded && <SkeletonLoader />}
                  {firstImageLoaded && <img src={Confetti} alt='Confetti' className='transition ease-in-out' />}
                  <div>
                    <p className='text-3xl fontLight text-center self-center'>Thank You!</p>
                    <p className='text-3xl fontLight text-center'>Your Vote Has Submitted Successfully</p>
                  </div>

                  {!secondImageLoaded && <SkeletonLoader />}
                  {secondImageLoaded && <img src={VoteLady} alt="Second description" className='size-9/12 self-center transition ease-in-out' />}
                  {/* <img src={VoteLady} alt='vote lady' className='size-9/12 self-center'/> */}
                  <Button
                    bgColor='bg-gradient-to-r from-[#00BBD3] from-44% to-[#53BC7F] to-100%'
                    shadow='shadow-[1px_5px_13px_3px_rgba(11,133,149,0.46)]'
                    padding='px-3 py-3'
                    color='text-white'
                    width='w-full'
                    fontSize='text-xl'
                    onClick={() => navigate(`${process.env.REACT_APP_HOMEPAGE}categories`, { state: { voterId } })}
                  >
                    Vote Again
                  </Button>
                </div>
              </div>
            </div>
          </BottomSheet>
        </div>
      </div>
    </div>
  )
};

export default EmployerDetails